import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { NotificationService, Stage } from '@heidelberg/control-center-frontend-integration';
import { CCAuthService, CCSession } from '@heidelberg/control-center-frontend-integration/auth';
import {
    CloudToolbarBreadcrumb,
    CloudToolbarUser,
    HdmuiAboutData,
    HDMUIAboutDialogService,
    IconRegistryService,
    LAUNCH_SCREEN,
    LaunchScreen,
    Membership,
    Notification,
} from '@heidelberg/hdmui-angular';

import { AppConfigService } from '@vmi/core';
import { ROUTE_PATH } from '@vmi/shared-models';
import { ContactFormDialogComponent, GlobalLoadingSpinnerService } from '@vmi/ui-smart';

import { BE_VERSION, FE_VERSION, UPDATE_TIMESTAMP } from './generated/versioningTimestamps';
import { NavigationService } from './navigation/navigation.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    public readonly APP_ID = 'vendor_managed_inventory';

    @ViewChild('drawer') drawer: MatSidenav | undefined;

    isSecondLevel$: Observable<boolean> = this.navigationService.level$.pipe(
        switchMap((result) => {
            const orgId = this.sessionService.getCurrentMembership()?.organizationId;
            return of(result === 2 && !!orgId);
        })
    );
    breadcrumbs$: Observable<CloudToolbarBreadcrumb[]> = this.navigationService.breadcrumbs$;
    mainMenuVisible$: Observable<boolean> | undefined = this.navigationService.mainMenuVisible$;
    subMenuVisible$: Observable<boolean> | undefined = this.navigationService.subMenuVisible$;
    isGlobalLoadingSpinnerActive$: Observable<boolean> = this.globalLoadingSpinnerService.isLoading$;

    notifications: Notification[] = [];

    toolbarUser: CloudToolbarUser | undefined;

    initialLoadDone = false;

    stage!: Stage;

    hideGlobalLegalLine = false;

    readonly #PAGES_TO_HIDE_GLOBAL_LEGAL_LINE = [`${ROUTE_PATH.deliveries}/${ROUTE_PATH.deliveryProcessing}`];

    constructor(
        @Inject(LAUNCH_SCREEN) private readonly launchScreen: LaunchScreen,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly translateService: TranslateService,
        private readonly appConfigService: AppConfigService,
        private readonly sessionService: CCAuthService,
        private readonly navigationService: NavigationService,
        private readonly notificationService: NotificationService,
        private readonly globalLoadingSpinnerService: GlobalLoadingSpinnerService,
        private readonly aboutDialogService: HDMUIAboutDialogService,
        private readonly iconRegistryService: IconRegistryService
    ) {
        this.stage = this.appConfigService.getStage();

        this.sessionService.currentSession$
            .pipe(
                untilDestroyed(this),
                tap((session) => {
                    this.toolbarUser = this.getToolbarUserFromSession(session);
                })
            )
            .subscribe();

        this.listenForHidingGlobalLegalLine();

        this.iconRegistryService.registerWatermark('./assets/icons/HDM-Inventory-Management-Cutout.svg');
    }

    ngOnInit(): void {
        this.notificationService.notifications$.pipe(untilDestroyed(this)).subscribe((notifications) => {
            this.notifications = notifications;
        });
    }

    ngAfterViewInit(): void {
        const sessionLoadReady = this.sessionService.isInitialLoadFinished();
        if (sessionLoadReady) {
            this.launchScreen.hide();
        }
        setTimeout(() => {
            this.initialLoadDone = this.sessionService.isInitialLoadFinished();
            this.navigationService.firstCheckUrl();
        }, 100);
    }

    public onContact(): void {
        this.dialog.open(ContactFormDialogComponent, {
            data: {},
            width: '80vw',
            maxWidth: '600px',
        });
    }

    public signOut(): void {
        this.sessionService.signOut();
    }

    public showAbout(): void {
        this.aboutDialogService.open(this.getHDMUIAboutData());
    }

    public onNotificationRemove(notifications: Notification[]): void {
        if (notifications.length === 1) {
            const notificationId = notifications[0].id;
            this.notificationService.delete(notificationId).subscribe(() => {
                this.notifications = this.notifications.filter((n) => n.id !== notificationId);
            });
        } else if (notifications.length > 1) {
            this.notificationService.deleteAll().subscribe(() => {
                this.notifications = [];
            });
        }
    }

    private getToolbarUserFromSession(s: CCSession): CloudToolbarUser {
        const orgId = s.activeMembership?.organizationId;
        return {
            id: s.user.id,
            fullName: s.user.fullName,
            avatarUrl: s.user.avatarUrl ?? undefined,
            activeOrganizationId: orgId ?? '',
            memberships:
                Object.keys(s.user.memberships).length > 0
                    ? s.user.memberships.map((m) => {
                          const orgMembership: Membership = {
                              id: m.id,
                              organization: {
                                  id: m.organizationId,
                                  name: `${m.organization.name}`,
                                  logoUrl: m.organization.logoUrl ?? undefined,
                              },
                              roles: m.ccRoles,
                          };
                          return orgMembership;
                      })
                    : [],
        } as CloudToolbarUser;
    }

    private listenForHidingGlobalLegalLine(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.hideGlobalLegalLine = this.#PAGES_TO_HIDE_GLOBAL_LEGAL_LINE.some((url) =>
                    this.router.url.includes(url)
                );
            });
    }

    private getHDMUIAboutData(): HdmuiAboutData {
        return {
            appTitle: this.translateService.instant('inventory.app.title'),
            appCategory: this.translateService.instant('*.appCategory.production'),
            timestamp: {
                text: this.translateService.instant('*.lastUpdateOn'),
                value: UPDATE_TIMESTAMP,
            },
            licenses: {
                text: this.translateService.instant('*.thirdPartyLicences'),
                path: './assets/generated/3rdpartylicences.txt',
            },
            versions: [
                {
                    text: this.translateService.instant('*.versionUi'),
                    value: FE_VERSION,
                },
                {
                    text: this.translateService.instant('*.versionService'),
                    value: BE_VERSION,
                },
            ],
        };
    }
}
