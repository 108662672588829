import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { CloudToolbarBreadcrumb } from '@heidelberg/hdmui-angular';

import { ROUTE_PARAMS, ROUTE_PATH } from '@vmi/shared-models';
import { absRouteWithOrg } from '@vmi/utils';
import { PermissionService } from '@vmi/core';

export const NAV_LEVEL_1 = 1;
export const NAV_LEVEL_2 = 2;
export type NAV_LEVEL = 1 | 2;

export interface NavLink {
    id: string;
    name: string;
    path: string;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    readonly #inventoryI18n = 'inventory.navigation.inventory';
    readonly #levelSubject = new BehaviorSubject<NAV_LEVEL>(NAV_LEVEL_2);
    readonly level$ = this.#levelSubject.asObservable();

    readonly #linksSubject = new BehaviorSubject<NavLink[]>([]);
    readonly links$ = this.#linksSubject.asObservable();

    readonly #breadcrumbsSubject: BehaviorSubject<CloudToolbarBreadcrumb[]> = new BehaviorSubject<
        CloudToolbarBreadcrumb[]
    >([]);
    readonly breadcrumbs$: Observable<CloudToolbarBreadcrumb[]> = this.#breadcrumbsSubject.asObservable();

    readonly #mainMenuVisibleSubject = new BehaviorSubject<boolean>(true);
    readonly mainMenuVisible$ = this.#mainMenuVisibleSubject.asObservable();

    readonly #subMenuVisibleSubject = new BehaviorSubject<boolean>(true);
    readonly subMenuVisible$ = this.#subMenuVisibleSubject.asObservable();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly location: Location,
        private readonly permissionService: PermissionService,
        private readonly sessionService: CCAuthService,
        private readonly translateService: TranslateService
    ) {
        this.router.events
            .pipe(
                // identify navigation end
                filter((event) => event instanceof NavigationEnd),
                // tap(event => console.log('NavService - event', event)),
                // now query the activated route
                map(() => this.rootRoute(this.route)),
                filter((route1: ActivatedRoute) => route1.outlet === 'primary')
            )
            .subscribe((route1: ActivatedRoute) => {
                this.checkUrl(this.location.path(true), route1.snapshot);
            });
    }

    public setLevel(level: NAV_LEVEL) {
        this.#levelSubject.next(level);
    }

    public setBreadcrumbs(breadcrumbs: CloudToolbarBreadcrumb[]) {
        this.#breadcrumbsSubject.next(breadcrumbs);
    }

    public firstCheckUrl(): void {
        this.checkUrl(this.router.url, this.route.snapshot);
    }

    private checkUrl(url: string, snapshot: ActivatedRouteSnapshot) {
        const mainMenuVisible = true;
        let subMenuVisible = false;
        const organizationId = this.sessionService.getCurrentMembership()?.organizationId;

        if (organizationId && this.isMainPage(url, organizationId)) {
            this.setConfigForMainPages(organizationId);
            subMenuVisible = true;
        } else if (organizationId && this.isStockCountingPage(url, organizationId)) {
            this.setConfigForStockCountingPage(organizationId);
        } else if (organizationId && this.isProductDetailsPage(url, organizationId, snapshot)) {
            this.setConfigForProductDetailsPage(organizationId);
        } else if (organizationId && this.isDeliveryProcessingPage(url, organizationId, snapshot)) {
            this.setConfigForDeliveryProcessingPage(organizationId);
        } else if (organizationId && this.isConsumptionReportPage(url, organizationId)) {
            this.setConfigForConsumptionReportPage(organizationId);
        } else {
            this.setLevel(NAV_LEVEL_1);
        }

        this.#mainMenuVisibleSubject.next(mainMenuVisible);
        this.#subMenuVisibleSubject.next(subMenuVisible);
    }

    private setConfigForProductDetailsPage(organizationId: string): void {
        this.setLevel(NAV_LEVEL_2);
        this.setBreadcrumbs([
            {
                text: this.translateService.instant(this.#inventoryI18n),
                routerLink: [absRouteWithOrg(organizationId, ROUTE_PATH.inventory)],
            },
            {
                text: this.translateService.instant('inventory.productDetails'),
            },
        ]);
    }

    private setConfigForDeliveryProcessingPage(organizationId: string): void {
        this.setLevel(NAV_LEVEL_2);
        this.setBreadcrumbs([
            {
                text: this.translateService.instant('inventory.navigation.deliveries'),
                routerLink: [absRouteWithOrg(organizationId, ROUTE_PATH.deliveries)],
            },
            {
                text: this.translateService.instant('inventory.deliveryProcessing'),
            },
        ]);
    }

    private setConfigForConsumptionReportPage(organizationId: string): void {
        this.setLevel(NAV_LEVEL_2);
        this.setBreadcrumbs([
            {
                text: this.translateService.instant('inventory.navigation.transactions'),
                routerLink: [absRouteWithOrg(organizationId, ROUTE_PATH.transactions)],
            },
            {
                text: this.translateService.instant('inventory.consumptionReport'),
            },
        ]);
    }

    private setConfigForStockCountingPage(organizationId: string): void {
        this.setLevel(NAV_LEVEL_2);
        this.setBreadcrumbs([
            {
                text: this.translateService.instant(this.#inventoryI18n),
                routerLink: [absRouteWithOrg(organizationId, ROUTE_PATH.inventory)],
            },
            {
                text: this.translateService.instant('inventory.stockCounting'),
            },
        ]);
    }

    private setConfigForMainPages(organizationId: string): void {
        this.setLevel(NAV_LEVEL_1);
        this.buildListsLinks(organizationId);
    }

    private isProductDetailsPage(url: string, organizationId: string, snapshot: ActivatedRouteSnapshot): boolean {
        return url.endsWith(
            absRouteWithOrg(
                organizationId,
                `${ROUTE_PATH.inventory}/${ROUTE_PATH.productDetails}/${encodeURIComponent(
                    snapshot.paramMap.get(ROUTE_PARAMS.productId) as string
                )}`
            )
        );
    }

    private isConsumptionReportPage(url: string, organizationId: string): boolean {
        return url.endsWith(
            absRouteWithOrg(organizationId, `${ROUTE_PATH.transactions}/${ROUTE_PATH.consumptionReport}`)
        );
    }

    private isDeliveryProcessingPage(url: string, organizationId: string, snapshot: ActivatedRouteSnapshot): boolean {
        const internalDeliveryNumber = snapshot.paramMap.get(ROUTE_PARAMS.internalDeliveryNumber);

        return url.endsWith(
            absRouteWithOrg(
                organizationId,
                internalDeliveryNumber
                    ? `${ROUTE_PATH.deliveries}/${ROUTE_PATH.deliveryProcessing}/${encodeURIComponent(
                          internalDeliveryNumber
                      )}`
                    : `${ROUTE_PATH.deliveries}/${ROUTE_PATH.deliveryProcessing}`
            )
        );
    }

    private isStockCountingPage(url: string, organizationId: string): boolean {
        return url.endsWith(absRouteWithOrg(organizationId, `${ROUTE_PATH.inventory}/${ROUTE_PATH.stockCounting}`));
    }

    private isMainPage(url: string, organizationId: string): boolean {
        return (
            url.endsWith(absRouteWithOrg(organizationId, ROUTE_PATH.dashboard)) ||
            url.endsWith(absRouteWithOrg(organizationId, ROUTE_PATH.inventory)) ||
            url.endsWith(absRouteWithOrg(organizationId, ROUTE_PATH.deliveries)) ||
            url.endsWith(absRouteWithOrg(organizationId, ROUTE_PATH.transactions))
        );
    }

    private buildListsLinks(organizationId: string) {
        this.#linksSubject.next([]);

        const listLinks: NavLink[] = [];

        this.addLink(
            {
                id: ROUTE_PATH.dashboard,
                name: 'inventory.navigation.dashboard',
                path: absRouteWithOrg(organizationId, ROUTE_PATH.dashboard),
            },
            organizationId,
            listLinks
        );

        this.addLink(
            {
                id: ROUTE_PATH.inventory,
                name: this.#inventoryI18n,
                path: absRouteWithOrg(organizationId, ROUTE_PATH.inventory),
            },
            organizationId,
            listLinks
        );

        this.addLink(
            {
                id: ROUTE_PATH.deliveries,
                name: 'inventory.navigation.deliveries',
                path: absRouteWithOrg(organizationId, ROUTE_PATH.deliveries),
            },
            organizationId,
            listLinks
        );

        this.addLink(
            {
                id: ROUTE_PATH.transactions,
                name: 'inventory.navigation.transactions',
                path: absRouteWithOrg(organizationId, ROUTE_PATH.transactions),
            },
            organizationId,
            listLinks
        );

        this.#linksSubject.next(listLinks);
    }

    private addLink(link: NavLink, organizationId: string, toList: NavLink[]) {
        if (this.permissionService.checkLinkRoute(link.path, organizationId)) {
            toList.push(link);
        }
    }

    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
